const MONITOR_HEADER = [
  { text: 'Order', align: 'center', value: 'index', sortable: false, width: '5%' },
  { text: 'Activity', align: 'start', value: 'activity', sortable: false, width: '20%' },
  { text: 'Status', align: 'center', value: 'status', sortable: false, width: '10%' },
  { text: 'Time Taken', align: 'center', value: 'timeTaken', sortable: false, width: '15%' },
  {
    text: 'Time of Completion',
    align: 'start',
    value: 'completedTime',
    sortable: false,
    width: '20%'
  },
  { text: 'Monitor', align: 'center', value: 'click', sortable: false, width: '20%' }
];

const ACTIVITIES = [
  {
    text: 'All Activities',
    value: ''
  },
  {
    text: 'Scope'
  },
  {
    text: 'Community'
  },
  {
    text: 'Team'
  },
  {
    text: 'Train'
  },
  {
    text: 'Research'
  },
  {
    text: 'Tinker'
  },
  {
    text: 'Ideate'
  },
  {
    text: 'Pitch'
  },
  {
    text: 'Forum'
  },
  {
    text: 'Make'
  },
  {
    text: 'Demo'
  },
  {
    text: 'Auto Apply'
  },
  {
    text: 'Interview'
  },
  {
    text: 'Offer'
  },
  {
    text: 'Welcome'
  },
  {
    text: 'Contact'
  },
  {
    text: 'Meet'
  },
  {
    text: 'Timesheet'
  },
  {
    text: 'Deliverable'
  },
  {
    text: 'Workspace'
  },
  {
    text: 'Signoff'
  }
];

export { MONITOR_HEADER, ACTIVITIES };
