var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"monitor__activities"},[_c('v-data-table',{attrs:{"headers":_vm.header,"items":_vm.filteredActivities,"sort-by":"resource","items-per-page":100,"hide-default-footer":true},scopedSlots:_vm._u([{key:"item.click",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"font-weight-bold",attrs:{"dark":item.unlocked,"depressed":"","rounded":"","disabled":!item.unlocked,"ripple":false},on:{"click":function($event){return _vm.handleMonitorButtonClick(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v("Monitor")],1)]}},{key:"item.activity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_c('h2',[_vm._v(_vm._s(item.activity.charAt(0).toUpperCase() + item.activity.slice(1)))])])]}},{key:"item.progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"green","value":"90"}})]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.unlocked)?_c('v-icon',{attrs:{"color":"green","dark":""}},[_vm._v("mdi-lock-open")]):_c('v-icon',{attrs:{"color":"red","dark":""}},[_vm._v("mdi-lock")])]}},{key:"item.type",fn:function(){return [_c('v-chip',{attrs:{"color":"purple darken-4","dark":"","outlined":"","small":"","disabled":""}},[_vm._v("Deliverable")])]},proxy:true},{key:"item.completedTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.completedTime ? _vm.formatDate(item.completedTime) : item.activity === 'start' ? 'Completed at start' : item.completed ? 'Completed' : 'Not completed yet')+" ")]}},{key:"item.group",fn:function(){return [_c('v-chip',{attrs:{"color":"blue darken-4","dark":"","x-small":"","label":"","disabled":""}},[_vm._v("Research")])]},proxy:true},{key:"item.timeTaken",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatTimeTaken(item.timeTaken)))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }